import React, { Component, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { AltCheckbox } from '../../components/AltCheckbox';
import PageClean from '../default/PageClean';
import Select, { createFilter } from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import Axios from '../../config/Axios';
import imgfLoading from '../../assets/img/preloader-black.gif'
import Alert from '../../components/Alert';

function RenderForm(props) {
  const { fnCloseModal, fnHandleSubmit, aModules } = props
  const [aData, fnSetAData] = useState({})
  const [rcmpAlertMsg, fnSetRcmpAlertMsg] = useState(null)
  function fnHandleOnchangeInput(evt, type) {

    if (type === 'name') {
      fnSetAData({ ...aData, name: evt.target.value })
    } else {
      fnSetAData({ ...aData, link: evt.target.value })
    }
  }

  function fnHandleOnChangeModule(evt) {
    fnSetAData({ ...aData, module: evt.value })
  }

  function fnHandleSubmitVideo(e) {
    e.preventDefault()
    e.stopPropagation()
    if (!aData.name || !aData.link || !aData.module) {
      fnSetRcmpAlertMsg(<Alert type="error" isOpen onCloseAlert={() => { }}>
        {"Preencha todos os campos obrigatórios!"}
      </Alert>)
      return
    }
    const youtubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
    if (!youtubeRegex.test(aData.link)) {
      fnSetRcmpAlertMsg(<Alert type="error" isOpen onCloseAlert={() => { }}>
        {"O link deve ser um URL válido do YouTube!"}
      </Alert>);
      return;
    }
    e.stopPropagation()
    e.preventDefault()
    fnHandleSubmit(aData)
  }

  
  return (
    <form className="form" style={{ height: "350px" }}>
      
      {rcmpAlertMsg}
      <div style={{ marginBottom: "15px" }} className="split1">
        <label style={{ margin: "0" }} >
          Nome do vídeo <span className="required">*</span></label>
        <input
          onChange={(e) => fnHandleOnchangeInput(e, 'name')}
          id="norm_cinitials"
          name="norm_cinitials"
          // value={oNorm.norm_cinitials || ''}
          required
          type="text"
          className="form-control"
        />
      </div>
      <div style={{ marginBottom: "30px" }} className="split1">
        <label style={{ margin: "0" }} >
          Link do vídeo <span className="required">*</span></label>
        <input
          onChange={(e) => fnHandleOnchangeInput(e, 'link')}
          id="norm_cinitials"
          name="norm_cinitials"
          // value={oNorm.norm_cinitials || ''}
          required
          type="text"
          className="form-control"
        />
      </div>
      <div style={{ marginBottom: "30px" }} className="split1">
        <label style={{marginBottom: "0"}}>
          Módulo do Vídeo <span className="required">*</span></label>
        <Select
          label="Módulo"
          isClearable
          className="selectCustom"
          classNamePrefix="react-select"
          noOptionsMessage={() => 'Requisito não encontrado'}
          placeholder="Selecione"
          options={aModules}
          maxMenuHeight={90}
          required
          // filterOption={oFilterOption}
          // defaultValue={oInitialParentRequisite}
          onChange={(e) => fnHandleOnChangeModule(e)}
        />
      </div>
      


      <div className="button-stack" style={{ paddingTop: "30px" }}>
        <button type="button" className="btn alternate back btn btn-outline-secondary" onClick={fnCloseModal}>
          Cancelar
        </button>
        <button type="submit" title="Salvar" className="btn btn btn-secondary" onClick={e => fnHandleSubmitVideo(e)}>
          Salvar
        </button>
      </div>
    </form>

  );
}

function StatusSwitcher(props){
  const {oVideo, oParams, fnSetStatusChange, fnSetUpdateVideosList} = props
  const [bIschecked, fnSetIsChecked] = useState(oVideo.vt_bisactive)


  async function fnHandleChangeStatus(e) {
    fnSetStatusChange({ id: oVideo.vt_nid, reqCompleted: false, checked: oVideo.vt_bisactive })
    fnSetIsChecked(prev => !prev)
    try{
      const response = await Axios.patch(`video-tutorial/${oVideo.vt_nid}/change-status`, { vt_bisactive: oVideo.vt_bisactive ? 0 : 1 })
      fnSetUpdateVideosList(prev => !prev)
    }catch(err){
      console.log(err)
    }
    finally{
      fnSetStatusChange({ id: null, reqCompleted: true })
    }
  }
  
  return (
    (oParams.id === oVideo.vt_nid && !oParams.reqCompleted) ? (<div className="center-loading-image" style={{ width: "100%", height: "100%" }}>
      <img alt='Carregando' src={imgfLoading} width={"50%"}/>
    </div>) : (<AltCheckbox
      label="Ativo"
      onChange={e => fnHandleChangeStatus(e)}
      checked={bIschecked}
      className={''}
    />)
    
  )
}

export default function TutorialVideosIndex() {


  const [bIsActive, fnSetBIsActive] = useState(false)
  const [bAddNewVideo, fnSetBAddNewVideo] = useState(false)
  const [aListVideos, fnSetAListVideos] = useState([])
  const [bUpdatedVideosList, fnSetUpdateVideosList] = useState(false)
  const [bGetVideosListDone, fnSetGetVideosListDone] = useState(false)
  const [oStatusChange, fnSetStatusChange] = useState({id: null, reqCompleted: true})
  const nUserId = Number(localStorage.getItem("nUserId"))
  const aModules = [
    { value: 1, label: 'Painel da Qualidade' },
    { value: 2, label: 'Painel Operacional' },
    { value: 3, label: 'Gestão de Auditorias' },
    { value: 4, label: 'Gestão de Documentos' },
    { value: 5, label: 'Controle Interno de Qualidade' }
  ]

  function fnHandleOpenAddVideo() {
    fnSetBAddNewVideo(true)
  }
  function fnHandleCloseAddVideo() {
    fnSetBAddNewVideo(false)
  }


  async function fnHandleSubmitVideo(data) {
    fnSetGetVideosListDone(false)
    const oDataFormated = {
      "vt_ctitle": data.name,
      "vt_clink": data.link,
      "mod_nid": data.module,
      "adm_nid": nUserId
    }
    try {
      await Axios.post('/video-tutorial', oDataFormated)
    } catch (err) {
      console.log(err)
    } finally {
      fnSetUpdateVideosList(prev => !prev)
      fnSetBAddNewVideo(false)
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const response = await Axios.get(`/video-tutorial`);
        if (response.status === 200) {
          fnSetAListVideos(response.data)
        }
      } catch (err) {
        console.log(err);
      }finally{
        fnSetGetVideosListDone(true)
      }
    })();
  }, [bUpdatedVideosList, nUserId])
  return (
    <div>
      <section id="container" className="centered">
        <div>
          {bAddNewVideo && (
            <SweetAlert
              customClass="modal-edit md"
              title=""
              // onConfirm={this.hideAlert}
              showConfirm={false}
            >
              <div className="head-modal">
                Novo vídeo
                <span role="button" tabIndex="0" onKeyPress={fnHandleCloseAddVideo} onClick={fnHandleCloseAddVideo} className="close" href="" />
              </div>
              <PageClean >
                <RenderForm fnCloseModal={fnHandleCloseAddVideo} fnHandleSubmit={fnHandleSubmitVideo} aModules={aModules} />
              </PageClean >
            </SweetAlert>
          )}
          <div className="title-button">
            <h1>Vídeos Tutoriais</h1>
            <div className="button-stack">
              <button type="button" onClick={(evt) => fnHandleOpenAddVideo(evt)} className="btn primary no-left-margin">
                Novo Vídeo
              </button>
            </div>
          </div>
          <div className="button-stack">

          </div>
          <div className="center-loading-image">
            <Table hover responsive striped>
              <thead>
                <tr>
                  <th>Nome do vídeo</th>
                  <th>Link</th>
                  <th>Exibir em</th>
                  <th>Ativo?</th>
                  <th>Criado por:</th>
                  <th>Cliques</th>
                </tr>
              </thead>

              <tbody>
                {aListVideos.length > 0 && (aListVideos.map((video, index) => {
                  return (
                    <tr key={index}>
                      <td>{video.vt_ctitle}</td>
                      <td>{video.vt_clink}</td>
                      <td>{aModules[video.mod_nid - 1].label}</td>
                      <td>
                        {
                          (<StatusSwitcher oVideo={video} oParams={oStatusChange} fnSetStatusChange={fnSetStatusChange} fnSetUpdateVideosList={fnSetUpdateVideosList}/>)
                        }
                     
                        
                      </td>
                      <td>{video.admin_user.adm_cname}</td>
                      <td>{video.vt_nview_counter}</td>
                    </tr>
                  )
                }))}
              </tbody>
              <tbody>

              </tbody>
            </Table>
          </div>
          {(!bGetVideosListDone) &&
            (<div className="center-loading-image" style={{ width: "100%" }}>
              <img alt='Carregando' src={imgfLoading} />
            </div>)
          }
        </div>
      </section>
    </div>
  );
}
